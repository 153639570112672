/* ######## COLORS ########## */

:root {
  --base0: rgba(0 0 0);
  --base10: rgba(50 50 50);
  --base20: rgba(70 70 70);
  --base40: rgba(120 120 120);
  --base60: rgba(170 170 170);
  --base80: rgba(200 200 200);
  --base85: rgba(230 230 230);
  --base90: rgba(240 240 240);
  --base100: rgba(255 255 255);
  --shadow: rgba(0, 0, 0, 0.5);
  --shop: rgba(240, 240, 240);

}


.GlobalDarkMode {
  --base0: rgba(255 255 255);
  --base10: rgba(230 230 230);
  --base20: rgba(200 200 200);
  --base40: rgba(170 170 170);
  --base60: rgba(120 120 120);
  --base80: rgba(70 70 70);
  --base85: rgba(60 60 60);
  --base90: rgba(20 20 20);
  --base100: rgba(0 0 0);
  --shadow: rgba(255, 255, 255, 0.5);
  --shop: rgba(255 255 255);
  /* transition: 2s; */
}




/* ######## FONTS ########## */

@font-face {
  font-family: 'Favorit-Regular';
  src:  url('/public/fonts/Favorit-Regular.otf');
}

@font-face {
  font-family: 'UCity-Regular';
  src:  url('/public/fonts/UCity-Regular.ttf');
}


@font-face {
  font-family: 'UCity-Semibold';
  src:  url('/public/fonts/UCity-Semibold.ttf');
}


* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  cursor: url('/public/cursor/cursor-51-grey.cur') 16 16, auto;
  font-family: 'Favorit-Regular';
  font-size: 14px;
  background-color: var(--base100);
}

#root {
  background-color: var(--base100);
  color: var(--base0);
  /* transition: 2s; */
}

a {
  text-decoration: none;
  color: var(--base0);
  cursor: url('/public/cursor/cursor-81-grey.cur') 16 16, auto;
}

h4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

p {
  margin-top: 20px;
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--base90);
}

::-webkit-scrollbar-thumb {
  background: var(--base80);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--base60);
}















/* ######## HOMEPAGE ANIM ########## */



svg g:nth-of-type(1) path {
  stroke: var(--base0);
  fill: var(--base0);
  stroke-width: 0;
}

svg g:nth-of-type(2) path {
  stroke: var(--base0);
  /* fill: var(--base0); */
  stroke-width: 9;
}

svg g:nth-of-type(3) path {
  stroke: var(--base0);
  fill: var(--base0);
  stroke-width: 9;
}

svg g:nth-of-type(4) path {
  stroke: var(--base0);
  /* fill: var(--base0); */
  stroke-width: 2;
}


svg g:nth-of-type(10) path {
  stroke: var(--base0);
  /* fill: var(--base0); */
  stroke-width: 4;
}

svg g:nth-of-type(11) path {
  stroke: var(--base0);
  /* fill: var(--base0); */
  stroke-width: 2;
}

svg g:nth-of-type(12) path {
  stroke: var(--base0);
  /* fill: var(--base0); */
  stroke-width: 4;
}


svg g:nth-of-type(13) path {
  stroke: var(--base0);
  /* fill: var(--base0); */
  stroke-width: 4;
}

svg g:nth-of-type(14) path {
  stroke: var(--base0);
  /* fill: var(--base0); */
  stroke-width: 2;
}


/* ######## APP ########## */
.ErrorPage {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--base100);
  font-family: 'UCity-Regular' !important;
}

.ErrorPage h4 {
  font-weight: 600;
  font-size: 20px;
  color: var(--base0);
  text-shadow: 0px 0px 30px rgba(255,255,255,0.6);
  font-family: 'UCity-Regular' !important;
}

.ErrorPage p {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: var(--base40);
  margin-top: -10px;
  font-family: 'UCity-Regular' !important;
}

.ErrorPage h2 {
  font-weight: 300;
  font-size: 18px;
  margin-top: 70px;
  padding-bottom: 10px;
  color: var(--base40);
  font-family: 'UCity-Regular' !important;
}

.ErrorPage h2:hover {
  color: var(--base0);
}

.ErrorPage p, .ErrorPage h2, .ErrorPage h4 {
  opacity: 0;
}



/* ######## APP ########## */

.App {
  width: 100%;
  max-width: 1280px;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  background-color: var(--base100);
  overflow: hidden;
}


/* ######## CONTENT ########## */

.App-Col-Left {
  width: 300px;
  overflow-y: auto;
  padding-left: 10px;
  -webkit-overflow-scrolling: touch;
}

.App-Col-Right {
  width: calc(100% - 300px);
  padding-left: 100px;
  overflow-y: scroll;
  padding-right: 10px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.App .RemoveScrollBar {
  overflow-y: hidden;
  height: 100vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Content {
  max-width: 1000px;
  width: 100%;
}


/* ######## TOPBAR ########## */

.Topbar {
  max-width: 1000px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 0px solid var(--base60);
  margin-top: 50px;
}

.TopbarFixed {
  position: fixed;
  margin-top: 0px;
  top: 0px;
  z-index: 10;
  margin-right: 10px;
}

.Topbar .Column-Left {
  width: 50%;
}

.Topbar .Column-Right {
  width: 50%;
}

.Marketplaces {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.MarketplacesFixed {
  background-color: var(--base100);
}

.Marketplaces .Primary {
  width: 100%;
  height: 50px;
  padding-top: 10px;
  background-color: var(--base100);
  z-index: 10;
}

.Marketplaces .InnerPrimary {
  width: calc(50% - 6px);
  height: 100%;
  margin-left: 50%;
}

.MarketDivider {
  width: 0px;
  height: 1px;
  background-color: var(--base60);
  position: absolute;
  left: 50%;
  bottom: 0;
}

.Marketplaces .Primary, .Marketplaces .Secondary {
  opacity: 0;
}

.Marketplaces .Primary:hover {
  color: var(--base0)
}

.Marketplaces .Primary a {
  width: 100%;
  height: 100%;
  position: relative;
  color:var(--base20);
}

.Marketplaces .Primary a:hover {
  color:var(--base0);
}

.Marketplaces .Secondary a {
  color:var(--base40);
}

.Marketplaces .Secondary a:hover {
  color:var(--base0);
}


.Marketplaces .Secondary {
  width: 100%;
  height: 40px;
  color:var(--base40);
  margin-top: 10px;
}

.Marketplaces .Secondary:hover {
  color:var(--base0);
}

.Marketplaces .Row {
  position: relative;
  display: flex;
  flex-flow: column;
  font-size: 10px;
  padding-left: 6px;
}

.Marketplaces .Primary {
  position: fixed;
  top: 0;
}

.MarketplacesFixed .Primary {
  position: relative;
}

.MarketplacesFixed .Primary a {
  border-bottom: 1px solid var(--base60);
}

.Marketplaces .Row .Description {
  margin-top: 5px;
  width: 150px;
}

.Marketplaces .Row .Title {
  margin-top: 5px;
  width: 150px;
}


.MarketplacesFixed .Row {
  padding-left: 3px;
}


.Marketplaces .Row .Arrow {
  position: absolute;
  right: 0;
  top: 0;
}


.Marketplaces .Primary .Arrow {
  position: absolute;
  right: 3px;
  top: 0;
}



/* ######## SIDEBAR ########## */

.Sidebar {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.SidebarSection {
  position: relative;
}

.Sidebar .Buttons {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
}

.Sidebar .TopButton {
  width: 100%;
  height: 40px;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0,.54,.02,.99);
  position: relative;
  margin-bottom: 40px;
  padding-top: 10px;
}

.Sidebar .TopButton .Home {
  height: 40px;
  font-family: 'UCity-Regular', sans-serif;
}

.Sidebar .TopButton .DarkMode {
  height: 40px;
  width: 40px;
  position: absolute;
  right: 4px;
  top: -0px;
  transition: 2s;
  transition-property: background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: url('/public/cursor/cursor-81-grey.cur') 16 16, auto;
}

.DarkModeCircle {
  width: 14px;
  height: 14px;
  background-color: var(--base0);
  border-radius: 50%;
}

.Sidebar .TopButton .LightMode {
  background-color: var(--base100);
  transition: 2s;
  transition-property: background-color;
}


.Sidebar .Item {
  width: 100%;
  /* border-bottom: 1px solid var(--base60); */
  height: 120px;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  font-weight: 300;
  /* transition-duration: 0.5s; */
  /* transition-timing-function: cubic-bezier(0,.54,.02,.99); */
  background-color: var(--base100);
  opacity: 0;
  position: relative;
}


.Sidebar .Item-Open {
  height: 120px;
}

.Sidebar .Item:hover p{
  color: var(--base40);
}

.Sidebar .Top {
  width: 100%;
  height: 40px;
  display: flex;
  pointer-events: none;
  margin-top: 20px;
}

.Sidebar .Bottom {
  width: 100%;
  height: 40px;
  display: flex;
  pointer-events: none;
  font-size: 12px;
}

.Sidebar .Bottom p {
  color: var(--base60);
  -webkit-margin-before: 0em;
}

.Sidebar .Item .Text {
  width: 270px;
  height: 40px;
  display: flex;
  align-items: center;
  pointer-events: none;
  letter-spacing: 0.6px;
  color: var(--base10);

}

.Sidebar .Item .Number {
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 0px;
  pointer-events: none;
}

.Sidebar .Item .Description {
  width: 100%;
  height: 100%;
}

.Sidebar .Circle {
  width: 25px;
  height: 25px;
  border: 1px solid var(--base60);
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: 27px;
  transition: 2s;
  opacity: 0;
  z-index: 12;
}

.Sidebar .HideCircle {
  opacity: 0 !important;
  transition: 1s;
  transition-property: opacity;
}

.Sidebar .Hide {
  opacity: 0;
  transition: 1s;
  transition-property: opacity;
}

.Sidebar .Item .Line {
  margin: 0px;
}

.SidebarDivider {
  width: 0px;
  height: 1px;
  background-color: var(--base80);
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.Sidebar .SidebarSection .ComingSoon, .Sidebar .SidebarSection .ComingSoon .Text, .Sidebar .SidebarSection .ComingSoon .Description p {
  color: var(--base80)
}

.DesktopFooter {
  width: 200px;
  height: 40px;
  position: fixed;
  bottom: 5px;
  font-size: 10px;
  display: flex;
  flex-flow: column;
  color: var(--base60);
  margin-left: -5px;
  text-transform: uppercase;
}

.DesktopFooter .DesktopSocials {
  display: flex;
  opacity: 0;
  
}

.DesktopFooter .DesktopSocials a {
  color: var(--base60);
  opacity: 0;
}

.DesktopFooter .SocialButton {
  padding-left: 5px;
  padding-right: 5px;
}

.DesktopFooter .DesktopSocials a:hover {
  color: var(--base0);
}

.DesktopFooter .Copyright {
  padding-left: 5px;
  margin-top: 5px;
  opacity: 0;
}



/* ######## ABOUT ########### */

.AboutContent {
  opacity: 1;
}

.AboutContent h4 {
  -webkit-margin-before: 20px;
  -webkit-margin-after: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  font-weight: 300;
}



.AboutContent h4 {
  font-weight: 300;
  color:var(--base0);
}

.AboutContent h4, .AboutContent p, .AboutContent .TeamHeading, .AboutContent .CouncilHeading {
  opacity: 0;
}

.AboutContent p {
  color:var(--base40);
  max-width: 432px;
}

.Grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.CouncilGrid {
  justify-content: flex-start;
}

.Grid .Card {
  width: calc(25% - 8px);
  flex-direction: column;
  opacity: 0;
  margin-bottom: 20px;
}

.CouncilGrid .Card2, .CouncilGrid .Card3, .Grid .Card3, .Grid .Card4 {
  margin-left: 12px;
}


.Grid .Card .Image {
  width: 100%;
  overflow: hidden;
}

.Grid .Card .Image img {
  width: 100%;
  /* filter: grayscale(100%); */
  filter: grayscale(100%);
  transition: 1s;
}

.Grid .Card .Image img:hover {
  filter: grayscale(0%);
  transition: 0.3s;
}

.Grid .Card .Info {
  margin-top: 20px;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  font-size: 10px;
}

.Grid .Card .Name {
  width: 100%;
  height: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.Grid .Card .Title {
  width: 100%;
  height: 14px;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
  color:var(--base40);
}

.Grid .Card .Social {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 300;
  color:var(--base40);
}

.Grid .Card .Social a {
  color:var(--base40);
}

.Grid .Card .Social:hover a {
  color: var(--base0) ;
}



/* ######## VISION ########### */

.Vision h4, .Vision p {
  opacity: 0;
  max-width: 500px;
  line-height: 18.2px;
}

.Vision li {
  line-height: 18.2px;
  opacity: 0;
}

.Vision h4 {
  color: var(--base0);
  letter-spacing: 0.3px;
  font-weight: 300;
}

.Vision p, .Vision li {
  color: var(--base40)
}

.Vision .BottomHeader {
  opacity: 0;
}

.Vision .Grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.Vision .Grid .Section {
  min-height: 120px;
  opacity: 0;
}

.Vision .Grid .Row {
  width: 50%;
  height: 80px;
  position: relative;
  display: flex;
  flex-flow: column;
  color: var(--base40);
}

.Vision .Grid .Row .Name {
  width: 100%;
  height: 25px;
}


.Vision .Grid .Row .Description {
  color:var(--base60);
}

.Vision .Grid .Row .Number {
  position: absolute;
  top: 0;
  right: 40px;
}


/* ######## COMMUNITY ########### */

.Community p, .Community .TopHeader, .Community .BottomHeader, .Community .Grid .Row, .Community h4 {
  opacity: 0;
}

.Community .Marketplaces .Row {
  opacity: 1;
}

.Community .Chunk {
  min-height: 240px;
}

.Community .Grid .Section {
  /* height: 120px; */
}

.Community h4 {

  -webkit-margin-before: 0px;
  padding-top: 30px;
  font-weight: 300;

}
.Community p {
  max-width: 500px;
  line-height: 18.2px;
  color:var(--base40);
}


.Divider {
  width: 0%;
  /* margin-top: 60px; */
  /* margin-bottom: 60px; */
  height: 1px;
  background-color:var(--base80);
}

.Community .BottomHeader {
  height: 120px;
  margin-bottom: 0px;
}

.Community .Grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.Community .Grid .Section {
  min-height: 120px;
}

.Community .Grid .Row {
  width: 50%;
  height: 80px;
  position: relative;
  display: flex;
  flex-flow: column;
}

.Community .Grid .Row .Name {
  width: 100%;
  height: 25px;
}


.Community .Grid .Row .Description {
  color:var(--base60);
}

.Community .Grid .Row .Number {
  position: absolute;
  top: 0;
  right: 40px;
}




/* ######## MEDIA ########### */

.Media .Articles .Heading, .Media .Articles .Item,  .Media .Podcasts .Heading, .Media .Podcasts .Item  {
  opacity: 0;
}

.Section {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.Media .Divider {
  width: 0%;
  margin-top: 60px;
  margin-bottom: 60px;
  height: 1px;
  background-color:var(--base80);
}

.Articles {
  padding-top: 30px;
}

.Podcasts {
  margin-top: 30px;
}

.Media .Articles .Column-Right {
  padding-left: 10px;
}

.Column {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
}

.Column .Heading {
  font-weight: 300;
}

.Column .Item {
  width: 100%;
  height: 120px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  text-transform: uppercase;
}

.Column .Item:hover .Description {
  color:var(--base0);
}

.Column .Item:hover .Author {
  color:var(--base0);
}

.Column .Item:hover .Date {
  color:var(--base0);
}

.Column .Item:hover a {
  color:var(--base0);
}

.Column .Item .Title {
  font-weight: 300;
  height: 28px;
}

.Column .Item .Episode {
  font-weight: 300;
  height: 36px;
  width: calc(100% - 45px);
}

.Column .Item .Description {
  color:var(--base60);
  height: 28px;
}

.Column .Item .Author {
  color:var(--base40);
  height: 28px;
}

.Column .Item .Date {
  color:var(--base60);
  font-size: 10px;
  height: 28px;
}


.Column .Item .Link {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  height: 28px;
}

.Column .Item .Link:hover {
  font-weight: 800;
}


.Column .Item .Link .Icon {
  font-size: 10px;
  margin-top: 0px;
  margin-left: 7px;
}

.Media .Column a {
  color: var(--base40);
}

.Media .Column a:hover {
  color: var(--base0);
}

.AlsoAvailable {
  height: 80px;
  background-color: var(--base100);
  bottom: 0px;
  color: var(--base60);
  letter-spacing: 0.3px;
  font-size: 11px;
  padding-bottom: 20px;
  padding-top: 10px;
  padding-right: 20px;
  display: none;
}

.AlsoAvailable .AATitle {
  height: 20px;
}

.AlsoAvailable .AALinks {
  height: 20px;
  display: flex;
  margin-top: 10px;
}

.AlsoAvailable .AALinks a {
  color: var(--base60);
}

.AlsoAvailable .AALinks span {
  margin-left: 20px;
  margin-right: 20px;
  color: var(--base60);
}






/* ######## GALLERY ########### */

.GalleryFixed {
  /* margin-top: 200px; */
}

.Marketplaces .Column-Left {
  display: flex;
  align-items: flex-end;
}

.Marketplaces .PageTitle {
  font-size: 10px;
  color:var(--base20);
  text-transform: uppercase;
  letter-spacing: 0.2px;
  width: 100px;
} 

.Marketplaces .PageTitle:hover {
  color: var(--base0);
}

.Gallery .Collection-Inner {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 25px;
  justify-content: flex-start;
}

.Gallery .Collection-Inner .Card {
  width: calc(25% - 6px);
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: relative;
  cursor: url('/public/cursor/cursor-81-grey.cur') 16 16, auto;
}

.Gallery .Collection-Inner .Card:nth-of-type(odd),
.Gallery .Collection-Inner .Card:nth-of-type(2),
.Gallery .Collection-Inner .Card:nth-of-type(6),
.Gallery .Collection-Inner .Card:nth-of-type(10),
.Gallery .Collection-Inner .Card:nth-of-type(14),
.Gallery .Collection-Inner .Card:nth-of-type(18),
.Gallery .Collection-Inner .Card:nth-of-type(22),
.Gallery .Collection-Inner .Card:nth-of-type(26),
.Gallery .Collection-Inner .Card:nth-of-type(30),
.Gallery .Collection-Inner .Card:nth-of-type(34),
.Gallery .Collection-Inner .Card:nth-of-type(38),
.Gallery .Collection-Inner .Card:nth-of-type(42),
.Gallery .Collection-Inner .Card:nth-of-type(46),
.Gallery .Collection-Inner .Card:nth-of-type(50),
.Gallery .Collection-Inner .Card:nth-of-type(54),
.Gallery .Collection-Inner .Card:nth-of-type(58),
.Gallery .Collection-Inner .Card:nth-of-type(62),
.Gallery .Collection-Inner .Card:nth-of-type(66),
.Gallery .Collection-Inner .Card:nth-of-type(70),
.Gallery .Collection-Inner .Card:nth-of-type(74),
.Gallery .Collection-Inner .Card:nth-of-type(78),
.Gallery .Collection-Inner .Card:nth-of-type(82),
.Gallery .Collection-Inner .Card:nth-of-type(86),
.Gallery .Collection-Inner .Card:nth-of-type(90),
.Gallery .Collection-Inner .Card:nth-of-type(94),
.Gallery .Collection-Inner .Card:nth-of-type(98)
{
  margin-right: 8px;
}

.Gallery .Collection-Inner .Card:hover .Image img {
  transform: scale(1.03);
  transition: 0.5s;
  /* transition-property: transform; */
  backface-visibility: none;
}



.Gallery .Collection-Inner .Card .Image {
  width: 100%;
  /* pointer-events: none; */
}
.Gallery .Collection-Inner .Card .Image:hover {
  /* border-bottom: 1px solid grey; */
}

.Gallery .Collection-Inner .Card .Image img {
  width: 100%;
  /* pointer-events: none; */
  transition: 2s;
  backface-visibility: none;
  transform: scale(1.001);
}

.Gallery .Collection-Inner .Card .Title {
  height: 15px;
  font-size: 10px;
  margin-top: 25px;
  pointer-events: none;
}

.Gallery .Collection-Inner .Card .Name {
  height: 15px;
  font-size: 10px;
  color:var(--base60);
  pointer-events: none;
}

.Gallery .Collection-Inner:hover .Card {
  filter: grayscale(100%);
  transition: 0.5s;
  transition-property: filter;
}

.Gallery .Collection-Inner .Card:hover {
  filter:grayscale(0%);
  transition: 0.5s;
  transition-property: filter;
}

.Footer {
  font-size: 10px;
  max-width: 50%;
  width: 100%;
  color: var(--base60);
  margin-top: 60px;
}


/* ########################### SINGLE VIEW ########################### */

.Single-View-Outer {
  width: 100%;
  overflow: hidden;
}

.Single-View-Inner {
  width: auto;
  display: inline-flex;
  flex-flow: row nowrap;
  transform: translateX(0px);
}

.Single-View-Inner .Card {
  width: 800px;
}

.Single-View-Inner .Card .Image {
  width: 100%;
}

.Single-View-Inner .Card .Image img {
  pointer-events: none;
}

.Arrows {
  width: 100px;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 30px;
  font-size: 14px;
  user-select: none;
}

.ArrowLeft, .ArrowRight {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GallerySingle .Collection-Inner .Card:hover .Image img {
  transform: scale(1.00);
  transition: 0s;
  transition-property: transform;
}

.GallerySingle .Collection-Inner .Card .Image:hover img {
  transform: scale(1.00);
  transition: 0s;
  transition-property: transform;
}

.GallerySingle .Collection-Inner .Card {
  width: 100%;
  max-width: 900px;
}



.GallerySingle .Collection-Inner .Card .Image {
  width: 100%;
}


.GallerySingle .Collection-Inner:hover .Card {
  filter: grayscale(0%);
  transition: 0.0s;
  transition-property: filter;
}

.GallerySingle .Collection-Inner .Card:hover {
  filter:grayscale(0%);
  transition: 0.0s;
  transition-property: filter;
}




/* ######## PAGE STORE ########### */

.PageStoreGallery {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  
}

.PageStoreGallery a {
  width: calc(50% - 10px);
  /* margin: 10px; */
}

.PageStoreGallery .Card {
  width: 100%;
  height: 100%;
  opacity: 0;
}


.PageStoreGallery .Card .Image {
  width: 100%;
  height: 350px;
  background-color: var(--shop);
  padding: 30px;
}


.PageStoreGallery .Card .Image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.PageStoreGallery .Card .Text {
  font-size: 10px;
  display: flex;
  flex-flow: column;
  position: relative;
  height: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}


.PageStoreGallery .Card .Text .Title{
  height: 14px;
}


.PageStoreGallery .Card .Text .Description{
height: 14px;
}


.PageStoreGallery .Card .Text .Price{
  right: 0;
  top: 14px;
  position: absolute;
}



/* ######## PRODUCT  ########### */

.ProductContainer {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 120px;
}

.ProductContainer .LeftColumn {
  width: 60%;
  /* height: 800px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ProductContainer .LeftColumn .FeatureImage {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--shop);
  /* padding: 100px; */
  
}

.ProductContainer .LeftColumn .FeatureImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
}

.ProductContainer .RightColumn {
  width: 40%;
  padding: 10px;
  padding-right: 0px;
  padding-top: 0px;
}


.ProductContainer .RightColumn .ZoomImage {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  background-color: var(--shop);
}


.ProductContainer .RightColumn .ZoomImage img {
  position: absolute;
  left: 0%;
  top: 0%;
  /* width: 1000px; */
}



.ProductContainer .RightColumn .ArrowControls {
  width: 100%;
  height: 30px;
  position: relative;
}


.ProductContainer .RightColumn .ArrowControls .ArrowLeft {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.ProductContainer .RightColumn .ArrowControls .ArrowRight {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}

.ProductContainer .RightColumn .ArrowControls .ArrowRight, .ProductContainer .RightColumn .ArrowControls .ArrowLeft {
  color: var(--base60);
}


.ProductContainer .RightColumn .Info {
  margin-top: 100px;
}


.ProductContainer .RightColumn .Info .Row {
  width: 100%;
  position: relative;
  font-size: 12px;
  text-transform:uppercase;
  margin-bottom: 20px;
  color: var(--base40);
  min-height: 80px;
}

.ProductContainer .RightColumn .Info .TopRow {
  color:var(--base40);
  border-bottom: 1px solid var(--base80);
  min-height: 60px;
  margin-bottom: 60px;
}

.ProductContainer .RightColumn .Info .MoreInfo {
  border-bottom: 1px solid var(--base80);
  margin-bottom: 0px;
  max-height: 40px;
  min-height: 40px;
  overflow: hidden;
  transition: max-height 0.5s;
  transition-timing-function: cubic-bezier(.44,0,.09,1)  ;
}

.ProductContainer .RightColumn .Info .MoreInfo .Right:hover {
  color: var(--base0)
}

.ProductContainer .RightColumn .Info .MoreInfo .Right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ProductContainer .RightColumn .Info .ShowMoreOpen {
  max-height: 500px;
  transition: max-height 1s;
  transition-timing-function: cubic-bezier(.44,0,.09,1)  ;
}

.ProductContainer .RightColumn .Info .TopRow a {
  color:var(--base20);
}

.ProductContainer .RightColumn .Info .TopRow a:hover {
  color:var(--base0);
}

.ProductContainer .RightColumn .Info .Row .Left {
  height: 50px;
}

.ProductContainer .RightColumn .Info .Row .Right {
  height: 50px;
  position: absolute;
  top: 0;
  right: 0px;
}

.ProductContainer .RightColumn .Info .Row .Description {
  text-transform:none;
  text-align: right;
  line-height: 16px;
  font-size: 10px;
}

.OtherProducts {
  width: 100%;
  height: 300px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
}

.OtherProducts a {
  height: 100%;
  width: calc(33.33% - 10px);
}


.OtherProducts a:hover .Card .Image img {
  transform: scale(1.2);
  transition: 5s;
  transition-property: transform;
}

.OtherProducts .Card  {
  height: 100%;
  width: 100%;
}


.OtherProducts .Card .Image {
  height: 260px;
  width: 100%;
  padding: 20px;
  background-color: var(--shop);
  overflow: hidden;
  /* border-bottom: 1px solid var(--base0); */
}

.OtherProducts .Card .Image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.OtherProducts .Card .Info {
  display: flex; 
  flex-flow: column nowrap;
  position: relative;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: var(--base40)
}

.OtherProducts .Card .Info .Title {
  height: 14px;
}

.OtherProducts .Card .Info .Description {
  height: 14px;
}

.OtherProducts .Card .Info .Price {
  position: absolute;
  right: 0;
  bottom: 0;
}


/* ########### HOME ################ */



.Canvas {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomepageAnim {
  max-width: 600px;
  width: 100%;
  height: 200px;
  margin-top: -50px;
}

.TopButton, .SidebarSection {
  opacity: 0;
}



@media screen and (min-width: 500px) {

.SidebarSection {
  transform: translate(0px, 0px) !important;
}

.Sidebar .MobileTopButton {
  height: 40px !important;
}

.Sidebar .MobileMenu {
  display: none;
}

.Sidebar .MobileCurrentPage {
  display: none;
}

.Sidebar .Divider {
  display: none;
}

.DesktopHeader {
  display: flex;
}

.StoreGalleryPage {
  display: none;
}

}





/* ############  TABLET  MENU ################ */

@media screen and (max-width: 1220px) {

.PageStoreGallery .Card .Image {
  padding: 0px;
}

}


/* ############  TABLET  MENU ################ */

@media screen and (max-width: 1000px) {

.PageStoreGallery .Card  .Image {
  height: 280px;
}

}



/* ############  TABLET  MENU ################ */

@media screen and (max-width: 900px) {

  .App-Col-Right {
    padding-left: 10px;

  }

  .Canvas-Inner .Title {
    font-size: 50px;
    display: flex;
    flex-flow: column;
  }

  .Gallery .Collection-Inner .Card {
    width: calc(50% - 8px);
  }

  .Gallery .Collection-Inner .Card .Image {
    width: 100%;
  }

  .Gallery .Collection-Inner .Card:nth-of-type(odd),
  .Gallery .Collection-Inner .Card:nth-of-type(2),
  .Gallery .Collection-Inner .Card:nth-of-type(6),
  .Gallery .Collection-Inner .Card:nth-of-type(10),
  .Gallery .Collection-Inner .Card:nth-of-type(14),
  .Gallery .Collection-Inner .Card:nth-of-type(18),
  .Gallery .Collection-Inner .Card:nth-of-type(22),
  .Gallery .Collection-Inner .Card:nth-of-type(26),
  .Gallery .Collection-Inner .Card:nth-of-type(30),
  .Gallery .Collection-Inner .Card:nth-of-type(34),
  .Gallery .Collection-Inner .Card:nth-of-type(38),
  .Gallery .Collection-Inner .Card:nth-of-type(42),
  .Gallery .Collection-Inner .Card:nth-of-type(46),
  .Gallery .Collection-Inner .Card:nth-of-type(50),
  .Gallery .Collection-Inner .Card:nth-of-type(54),
  .Gallery .Collection-Inner .Card:nth-of-type(58),
  .Gallery .Collection-Inner .Card:nth-of-type(62),
  .Gallery .Collection-Inner .Card:nth-of-type(66),
  .Gallery .Collection-Inner .Card:nth-of-type(70),
  .Gallery .Collection-Inner .Card:nth-of-type(74),
  .Gallery .Collection-Inner .Card:nth-of-type(78),
  .Gallery .Collection-Inner .Card:nth-of-type(82),
  .Gallery .Collection-Inner .Card:nth-of-type(86),
  .Gallery .Collection-Inner .Card:nth-of-type(90),
  .Gallery .Collection-Inner .Card:nth-of-type(94),
  .Gallery .Collection-Inner .Card:nth-of-type(98)
  {
    margin-right: 0px;
  }

  .Gallery .Collection-Inner .Card:nth-of-type(odd) {
    margin-right: 4px;
  }

  .Gallery .Collection-Inner .Card:nth-of-type(even) {
    margin-left: 4px;
  }

  .Grid .Card {
    width: calc(50% - 8px);
  }

  .CouncilGrid .Card2 {
    margin-left: 16px;
  }

  .CouncilGrid .Card3 {
    margin-left: 0px;
  }






}


/* ############  TABLET  MENU ################ */

@media screen and (max-width: 850px) {

  .Media .Section {
    flex-flow: column;
  }
  .Media .Column {
    width: 100%;
  }

  .PageStoreGallery .Card  .Image {
    height: 250px;
  }

  .Marketplaces .InnerPrimary {
    width: 100%;
    margin-left: 0%;
  }

  .MarketDivider {
    left: 0%;
  }

  .Marketplaces {
    flex-flow: column;
    flex-direction: column-reverse;
  }

  .Topbar {
    height: auto;
  }

  .Column-Left {
    margin-bottom: 0px;
  }

  .Marketplaces .PageTitle {
    margin-bottom: 0px;
  }

  .Articles {
    padding-top: 10px;
  }

  .AboutContent h4 {
    -webkit-margin-before: 0em;
  }

  .Marketplaces .Row {
    padding-left: 0px;
  }

  .Topbar .Column-Right {
    width: 100%;
  }

}


/* ############  TABLET  MENU ################ */

@media screen and (max-width: 700px) {

  .AboutContent .Grid {
    flex-flow: column;
  }

  .AboutContent .Card  {
    width: 100%;
  }

  .Community .Grid .Row {
    width: 100%;
  }

  .Gallery .Collection-Inner .Card {
    width: 100%;
  }

  .PageStoreGallery {
    flex-flow: column;
  }

  .PageStoreGallery a {
    width: 100%;
  }

  .Community .Grid .Row .Number {
    right: 20px;
  }

  .Gallery .Collection-Inner .Card:nth-of-type(odd) {
    margin-right: 0px;
  }

  .Gallery .Collection-Inner .Card:nth-of-type(even) {
    margin-left: 0px;
  }

  .CouncilGrid .Card2 {
    margin-left: 0px;
  }


}


/* ############  TABLET  MENU ################ */

@media screen and (max-width: 600px) {

  .App-Col-Left {
    width: 250px;
    transition: 0.5s;
  }

  .App-Col-Right {
    width: 100%;
    transition: 0.5s;
  }

}





/* ############  MOBILE MENU ################ */

@media screen and (max-width: 500px) {

  .Sidebar .MobileMenu {
    display: block;
  }

  .App-Col-Left {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    overflow: hidden;
    height: 100vh;
  }

  .App-Col-Right {
    height: calc(100vh - 95px);
  }


  .App-Col-Right .Canvas {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .App-Col-Right .Canvas-Outer {
    height: 100%;
  }

  .Canvas-Inner .Title {
    width: 100%;
    font-size: 40px;
    text-align: center;
    letter-spacing: 20px;
}

  .Sidebar {
    width: 100%;
    overflow: hidden;
  }

  .SidebarSection {
    background-color: var(--base100);
    transform: translate(0px,40px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .Sidebar .TopButton {
    margin-bottom: 0px;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: var(--base100);
    z-index: 4;
    overflow: hidden;
    border-bottom: 1px solid var(--base60)
  }

  .Sidebar .TopButton .Divider {
    width: 100%;
    height: 1px;
    background-color: var(--base60);
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .Sidebar .Bottom p {
    font-weight: 100;
  }

  .Sidebar .Item .Description {
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 100;
  }

  .Sidebar .Top {
    height: 20px;
    margin-top: 5px;
  }

  .Sidebar .Bottom {
    padding-top: 10px;
  }

  .Sidebar .Item {
    height: 120px !important;
    padding-top: 10px;
    overflow-x: hidden;
  }

  .Sidebar .LastItem {
    /* border-bottom: 1px solid var(--base0) */
  }

  .Sidebar .Item .Text {
    width: 100%;
    height: 100%;
  }

  .Sidebar .Item .Number {
    height: 100%;
  }

  /* ###### MOBILE MENU  */

  .Sidebar .MobileMenu .MarketplaceContainer a {
    opacity: 0;
  }

  .Sidebar .MobileMenu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    font-size: 11px;
    background-color: var(--base100);
    z-index: 3;
  }

  .Sidebar .MobileMenu .TopBar {
    width: 100%;
    height: 30px;
    padding-top: 10px;
    border-bottom: 1px solid var(--base60);
    margin-bottom: 20px;
  }
  
  .Sidebar .MobileMenu .MarketplaceContainer {
    width: 100%;
    height: 0px;
    overflow: hidden;
  }
  

  .Sidebar .MobileMenu a {
    width: 100%;
    height: 30px;
    position: relative;
    color:var(--base20);
  }

  .Sidebar .MobileMenu a:hover {
    width: 100%;
    height: 30px;
    position: relative;
    color: var(--base0);
  }

  .Sidebar .MobileMenu .Row {
    width: 100%;
    height: 30px;
  }

  .Sidebar .MobileMenu .Row:hover {
    background-color:var(--base90);
  }

  .Sidebar .MobileMenu .Arrow {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
  }

  .Sidebar .MobileMenu .Footer {
    display: flex;
    height: 20px;
    position: relative;
    color:var(--base20);
    margin-top: 0px;
    max-width: 100%;
  }

  .Sidebar .MobileMenu .Footer .Socials {
    display: flex;
    height: 30px;
    font-size: 9px;
  }

  .Sidebar .MobileMenu .Footer .Socials a {
    width: 60px;
    opacity: 0;
  }

  .Sidebar .MobileMenu .Footer .Copyright {
    position: absolute;
    right: 0;
    opacity: 0;
  }

  .Sidebar .MobileCurrentPage {
    width: 100%;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 40px;
    display: flex;
  }

  .Sidebar .MobileCurrentPage .Text {
    color:var(--base60)
  }

  .Sidebar .MobileCurrentPage .Number {
    position: absolute;
    top: -5px;
    right: 8px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid var(--base60);
    display: flex;
    justify-content: center;
    align-items: center;
    color:var(--base60)
  }

  .SidebarSection .Circle {
    display: none;
  }

  .SidebarSection .SidebarDividerFirst {
    display: none;
  }


/* ################# MOBILE CONTENT #######################  */

.App-Col-Right .Topbar {
  display: none;
}

.App-Col-Right {
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 95px;
  padding-left: 10px;
  z-index: 10;
}

.DesktopHeader {
  display: none;
}

/* ## HOME PAGE  */



/* ## ABOUT PAGE  */

.AboutContent h4 {
  font-size: 12px;
  letter-spacing: 0.4px;
}

.AboutContent p {
  font-size: 11px;
  letter-spacing: 0.3px;
}

.AboutContent .Grid .Card {
  padding: 10px;
}

.AboutContent .Grid .Card .Image img{
  filter: grayscale(00%) blur(0px);
}


/* ## VISION PAGE  */

.Vision p, .Vision li {
  font-size: 12px;
}

.Vision h4, .Vision p {
  max-width: 100%;
}

.Vision .Grid .Section {
  flex-flow: column;
}



/* ## COMMUNITY PAGE  */

.Community p {
  font-size: 11px;
}

.Community .Name {
  font-size: 14px;
  /* color:var(--base40); */
}

.Community .Description {
  font-size: 11px;
  color:var(--base60);
}

.Community .Number {
  font-size: 12px;
  color: var(--base0);
}

.Community .Grid .Row .Number {
  left: 300px;
}

.Community .Grid .Section {
  flex-flow: column;
}

.Community .Chunk {
  min-height: 120px;
}

/* ## MEDIA PAGE  */

.Articles, .Podcasts {
  flex-flow: column;
}

.Articles .Column-Left, .Podcasts .Column-Left {
  margin-bottom: 40px;
}

.Articles .Column, .Podcasts .Column {
  width: 100%;
}

.AlsoAvailable {
  display: flex;
  flex-flow: column;;
}

/* ## GALLERY PAGE  */

.App-Col-Right .GalleryFixed {
  margin-top: 0px;
}

.App-Col-Right .Gallery .Collection-Inner .Card {
  margin: 0px;
}

.Gallery .Collection-Inner:hover .Card { 
  filter: grayscale(0%);
  transition:none;
}

.Gallery .Collection-Inner .Card { 
  width: 100%;
  filter: grayscale(0%);
}

.Gallery .Collection-Inner .Card:hover { 
  transition: none;
}

.Gallery .Collection-Inner .Card:hover .Image img { 
  transform: scale(1);
  transition: none;
}

.Gallery .Collection-Inner .Card .Image { 
  width: 100%;
}




/* ## PAGESTORE GALLERY  */

.PageStoreGallery a {
  width: 100%;
}

.PageStoreProduct .ProductContainer {
  flex-flow: column;
  opacity: 0;
  margin-bottom: 20px;
}

.PageStoreProduct .ProductContainer .LeftColumn {
  width: 100%;
  height: 300px;
}

.PageStoreProduct .ProductContainer .FeatureImage {
  padding: 20px;
}

.PageStoreProduct .ProductContainer .RightColumn {
  width: 100%;
  padding: 0px;
}

.PageStoreProduct .ProductContainer .ZoomImage {
  display: none;
}

.PageStoreProduct .ProductContainer .ArrowControls {
  width: 100%;
}

.PageStoreProduct .ProductContainer .Info {
  margin-top: 0px;
  margin-bottom: 50px;
}
.PageStoreProduct .ProductContainer .Info .Row {
  /* height: auto; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.PageStoreProduct .ProductContainer .Info .PriceRow {
  /* height: auto; */
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--base80);
  min-height: 60px;
}



.PageStoreProduct .ProductContainer .Info .Row .Left {
  height: auto;
}

.PageStoreProduct .RightColumn .Info .Row .Description {
  line-height: 20px;
  height: auto;
}

.OtherProducts {
  height: auto;
  display: flex;
}

.OtherProducts a {
  width: 120px;
  margin: 4px;
}

.OtherProducts .Card .Image {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.OtherProducts .Card .Info {
  margin-top: 0px;
  font-size: 10px;
}

.OtherProducts .Card .Info .Title {
  position: relative;
  margin-top: 10px;
  text-transform: uppercase;
}

.OtherProducts .Card .Info .Description {
  position: relative;
  margin-top: 5px;
  color: var(--base60)
}

.OtherProducts .Card .Info .Price {
  position: relative;
  margin-top: 0px;
  color: var(--base60)
}

.StoreGalleryPage {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: var(--base40);
  letter-spacing: 0.5px;
}

.Footer {
  max-width: 100%;
}

.TopButton, .SidebarSection, .MobileMenu {
  opacity: 0;
}

.closeSidebar {
  height: 95px !important;
}

.DesktopFooter {
  display: none;
}



}






/* ######## SIGNUP ########## */


.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  font-family: UCity-Regular;
}

.BackgroundOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.0)
}

.LandingPage {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 20px;
  margin-top: -120px;
}
.LandingPage h1 {
  font-weight: 100;
  text-align: center;
  width: 100%;
  font-size: 56px;
  margin-top: 20px;
  margin-bottom: 10px;

}
.LandingPage p {
  font-weight: 100;
  text-align: center;
  width: 100%;
  font-size: 16px;

}
.LandingPage a {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.37);
}
.LandingPage a:hover {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.6);
}

.AsiaTour {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.App .LandingPage {
  width: 500px;
}

.LandingPage .SocialFooter {
  width: 100px;
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  bottom: 30px;
}

.LandingPage .SocialFooter a {
  border-bottom: 0px solid rgba(255, 255, 255, 0.37);
}

.LandingPage .SocialFooter .SocialIcon {
  width: 25px;
  color: white;
  margin-left: 10px;
}

.LandingPage .SocialFooter .SocialIcon img {
  width: 100% !important;
  height: 100% !important;
}
.LandingPage .SocialFooter .SocialIcon a {
  display: flex;
  width: 100%;
  height: 100%;
}
.LandingPage .SocialFooter .SocialIcon a:hover {
  transform: translate(0px,-4px);
  transition: 0.2s;
}
.LandingPage .SocialFooter .SocialIcon .Circle {
  width: 25px;
  height: 25px;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.LandingPage input {
  width: 400px;
}

#mc_embed_signup {
  width: 350px;
}

#mc_embed_signup .mc-field-group {

  width: 100%;

}

#mc_embed_signup .mc-field-group input {
  background-color: black;
  border: white solid 1px;
  font-size: 14px;
  height: 35px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  color: white;
  letter-spacing: 0.5px;
}


.LandingPage .Subscribe-button {
  background-color: rgb(255, 255, 255);
  color: black;
  width: 40%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

#mc_embed_signup .mc-field-group input::placeholder {
  color: rgba(255, 255, 255, 0.323);
  font-family: UCity-Regular;
}

.LandingPage .ThankYou {
  width: 300px;
  height: 50px;
  margin: auto;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-top: 20px;
}

h1, p, #mc_embed_signup, .AsiaTour, .SocialIcon {
  opacity: 0;
}


